import {Box} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useVuplex} from '../../hooks/useVuplex';
import {sendActionPanelClose} from '../../vuplex';
import MinimapClickActionToast from './Minimap/Toast/MinimapClickActionToast';
import PanoNoneForLocationToast from './Minimap/Toast/PanoNoneForLocationToast';
import PageTitle from './PageTitle';

const ActionPanel = ({}) => {
  const [laserHit, setLaserHit] = useState(null);

  useEffect(() => {
    document.body.style.background = 'none';
  }, []);


  useVuplex({
    actionPanelClose: () => {
      setLaserHit({
        latitude: null,
        longitude: null,
      });
    },
    laserAction: (data) => {
      setLaserHit(data);
    },
  })

  if (!laserHit) return null;

  return (
    <>
      <PageTitle title="ActionPanel - Wooorld"/>
      <Box
        h="100vh"
        w="100vw"
      >
        <MinimapClickActionToast
          brushStroke={laserHit.timeToDeletion && laserHit}
          latitude={laserHit.latitude}
          longitude={laserHit.longitude}
          onClose={sendActionPanelClose}
        />
        <PanoNoneForLocationToast onClose={sendActionPanelClose} />
      </Box>
    </>
  );


};

export default ActionPanel;
