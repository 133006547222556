import {CloseIcon} from '@chakra-ui/icons';
import {Flex, Stack, Text, useToast} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useEffect, useRef} from 'react';
import {MdMyLocation, MdVrpano} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import ButtonCircleIcon from '../../../../components/ButtonCircleIcon';
import ButtonMinor from '../../../../components/ButtonMinor';
import {sendNavToUser} from '../../../../vuplex';
import {PanoUnloadButton} from './PanoUnloadButton';

const ToastContent = ({
  color,
  isInView,
  isViewingDifferentPano,
  userId,
  username,
  onCenterMapClick,
  onClose,
}) => {

  return (
    <Flex
      alignItems="start"
      gap={3}
      w="full"
    >
      <PanoUnloadButton/>
      <Flex bg="#007AFF" borderRadius="2xl" color="white" p={2} alignItems="center" gap={2}>
        <Stack>

          <Flex justifyContent="space-evenly" gap={2}>
            {!isInView && (
              <ButtonCircleIcon
                icon={<MdMyLocation size="1.5em"/>}
                onClick={onCenterMapClick}
                tooltip={`Center map on ${username}`}
              />
            )}


            <ButtonMinor
              onClick={() => {
                sendNavToUser({userId});
              }}
              tooltip={`Teleport to ${username}`}
            >
              Go to&nbsp;<Text color={color} textAlign="center">{username}</Text>
              {isViewingDifferentPano && <MdVrpano color={color} size="2em"/>}
            </ButtonMinor>
          </Flex>
        </Stack>

        <ButtonMinor
          bg="none"
          borderLeft="solid white 1px"
          borderRadius={0}
          onClick={onClose}
        >
          <CloseIcon color="white"/>
        </ButtonMinor>
      </Flex>
    </Flex>
  );
};

const MinimapInWorldUserToast = ({color, isInView, panoId, userId, username, onCenterMapClick, onClose = _.noop}) => {
  const dispatch = useDispatch();

  const toast = useToast();
  const toastIdRef = useRef();

  const localPanoId = useSelector(s => s.inApp.panoId);
  const isViewingDifferentPano = panoId && localPanoId !== panoId;


  useEffect(() => {
    const closeCurrentToast = () => toast.close(toastIdRef.current);

    if (userId) {
      toast.closeAll();

      toastIdRef.current = toast({
        onCloseComplete: onClose,
        render: () => (
          <ToastContent
            color={color}
            isInView={isInView}
            isViewingDifferentPano={isViewingDifferentPano}
            userId={userId}
            username={username}
            onCenterMapClick={() => {
              onCenterMapClick();
              closeCurrentToast();
            }}
            onClose={() => {
              closeCurrentToast();
            }}
          />),
        duration: null,
        // icon: <MdVrpano color="#33bbff" size="1.5em"/>,
        isClosable: true,
        position: 'bottom',
      });
    } else {
      closeCurrentToast();
    }

    return closeCurrentToast;
  }, [isViewingDifferentPano, userId]);

  // useEffect(() => {
  //   if (panoId) {
  //     dispatch(pinsPanoIdSearchRequest({
  //       isNew: true,
  //       panoId,
  //     }));
  //   }
  // }, [panoId]);

  return null;
};

export default MinimapInWorldUserToast;
