import _ from 'lodash';
import {Hover, Tap} from './helpers/audioClips';

const sendMessage = (msg) => {
  if (!window.vuplex) {
    console.warn(`vuplex not on window object. attempted msg: ${JSON.stringify(msg)}`);
    return;
  }

  window.vuplex.postMessage(msg);
};

export const sendAudioClip = ({
  clip,
  clientX = window.innerWidth / 2,
  clientY = window.innerHeight / 2,
  pos3d,
}) => {
  sendMessage({
    Clip: clip,
    NormalizedX: clientX / window.innerWidth,
    NormalizedY: 1 - clientY / window.innerHeight,
    Position: null, // Vector3 overrides NormalizedXY
    Type: 'audioClip',
  });
};

export const sendAudioClick = ({clientX, clientY,}) =>
  sendAudioClip({
    clientX, clientY, clip: Tap,
  });

export const sendAudioHover = ({clientX, clientY,}) =>
  sendAudioClip({
    clientX, clientY, clip: Hover,
  });

export const getVuplexMessageHandlers = (handlers) => (event) => {
  const msg = JSON.parse(event.data);
  const handler = handlers[msg.type];
  if (_.isFunction(handler)) handler(msg.data);
};

export const sendVoiceInputRequest = async () => new Promise((res, rej) => {
  const timeoutSeconds = 5;

  const timeout = setTimeout(() => {
    rej(`no voice input received after ${timeoutSeconds}s`);
    window.vuplex.removeEventListener('message', handleVuplexMessage);
  }, timeoutSeconds * 1000);

  const handleVuplexMessage = getVuplexMessageHandlers({
    voiceInput: (voiceInput) => {
      if (_.startsWith(voiceInput, 'CANCELED') || _.startsWith(voiceInput, 'NOMATCH'))
        rej(`canceled or no match`);
      else
        res(voiceInput);

      window.vuplex.removeEventListener('message', handleVuplexMessage);
      clearTimeout(timeout);
    },
  });

  window.vuplex.addEventListener('message', handleVuplexMessage);

  sendMessage({
    Type: 'voiceInputRequest',
  });
});

const tooltipOffsetY = 0.08;

export const sendTooltip = ({message = '', clientX, clientY}) => sendMessage({
  Message: message,
  NormalizedX: clientX / window.innerWidth,
  NormalizedY: 1 - clientY / window.innerHeight - tooltipOffsetY,
  Type: 'tooltip',
});

export const sendAuthRequest = () => sendMessage({
  Type: 'auth',
});

export const sendRefreshTokenRequest = _.debounce( // prevent multiple failed API calls from refreshing
  () => {
    console.log('refreshing token');
    sendMessage({ // TODO: turn this into a promise like voiceInput
      Type: 'refreshToken',
    });
  },
  1000,
  { // https://lodash.com/docs/4.17.15#debounce
    'leading': true,
    'trailing': false
  }
);

export const sendDevConsoleClearRequest = () => sendMessage({
  Type: 'devConsoleClear',
});

export const sendDevConsoleDictationActivate = () => sendMessage({
  Type: 'devDictationActivate',
});

export const sendDevConsoleEntriesRequest = (skipCount) => sendMessage({
  SkipCount: skipCount,
  Type: 'devConsoleEntries',
});

export const sendDevConsoleMethodsRequest = () => sendMessage({
  Type: 'devConsoleMethods',
});

export const sendRefreshProfilePhoto =
  _.debounce(({userId, oculusOrgId}) => sendMessage({
    UserId: userId,
    OculusOrgId: oculusOrgId,
    Type: 'refreshUserProfilePhoto',
  }), 1000);

export const sendReportPlace = ({ownerId, placeId}) => sendMessage({
  ContentId: placeId,
  Defendant: ownerId,
  ReportType: 'Pins',
  Type: 'report',
});

export const sendReportSketch = ({userId}) => sendMessage({
  Defendant: userId,
  ReportType: 'Sketches',
  Type: 'report',
});

export const sendTooltipOff = () => sendMessage({
  Type: 'tooltip',
});


export const sendTextToSpeech = (textToSpeak) => {
  sendTextToSpeechStop();
  sendMessage({
    TextToSpeak: textToSpeak,
    Type: 'tts',
  });
};

export const sendTextToSpeechStop = () => sendMessage({
  Type: 'tts',
});

export const sendUpgrade = () => sendMessage({
  Type: 'upgrade',
});

export const sendNavTo = ({lat, lon, panoId = '', zoom = 18}) => sendMessage({
  Location: {Latitude: lat, Longitude: lon},
  PanoId: panoId,
  Type: 'navTo',
  Zoom: zoom,
});

export const sendNavToUser = ({userId}) => sendMessage({
  Type: 'navToUser',
  UserId: userId,
});
export const sendBasketball = ({}) => sendMessage({
  Type: 'basketball',
});

export const sendMapNormalizedScale = ({scaleNormalized}) => sendMessage({
  Type: 'mapNormalizedScale',
  ScaleNormalized: scaleNormalized,
});

export const sendMapSnapScale = ({scaleStep}) => sendMessage({
  Type: 'mapSnapScale',
  ScaleStep: scaleStep,
});

export const sendWebBrowser = ({
  color = '#3BF', // TODO: color coded chrome
  componentId,
  executeJavascriptOnLoad,
  height,
  url,
  width,
}) => sendMessage({
  Color: color.replace('#', ''),
  ComponentId: componentId,
  ExecuteJavascriptOnLoad: executeJavascriptOnLoad,
  Height: height,
  Type: 'webBrowser',
  URL: url,
  Width: width,
});

export const sendWebBrowserUnmount = (componentId) => sendMessage({
  ComponentId: componentId,
  IsComponentUnmount: true,
  Type: 'webBrowser',
});

export const sendPanoLoad = ({lat, lon, panoId = ''}) => sendMessage({
  Location: lat === undefined ? {/*null causes JSON parse error*/} : {Latitude: lat, Longitude: lon},
  PanoId: panoId,
  Type: 'panoLoad',
});

export const sendPanoIdSearch = ({lat, lon, panoId = ''}) => sendMessage({
  Location: lat === undefined ? null : {Latitude: lat, Longitude: lon},
  PanoId: panoId,
  Type: 'panoIdSearch',
});

export const sendPanoSave = ({lat, lon, panoId = ''}) => sendMessage({
  Location: lat === undefined ? null : {Latitude: lat, Longitude: lon},
  PanoId: panoId,
  Type: 'panoSave',
});

export const sendPanoUnload = () => sendMessage({
  IsUnload: true,
  PanoId: null,
  Type: 'panoLoad',
});

export const sendExploreSearch = ({lat, lon, placeId}) => sendMessage({
  Location: lat === undefined ? null : {Latitude: lat, Longitude: lon},
  PlaceId: placeId,
  Type: 'exploreSearch',
});

export const sendPlacesSearch = ({ids}) => sendMessage({
  PlaceIds: ids,
  Type: 'placesSearch',
});

export const sendActionPanelClose = () => sendMessage({
  Type: 'actionPanelClose',
});

export const sendAssistantRequest = ({locationName}) => sendMessage({
  Type: 'assistantRequest',
  LocationName: locationName,
});


export const sendPin = ({
  color = '#3BF',
  componentId,
  iconUrl,
  label,
  latitude,
  longitude,
  placeType,
  tooltip,
}) => sendMessage({
  Color: color.replace('#', ''),
  ComponentId: componentId,
  Icon: iconUrl,
  Label: label,
  Location: {Latitude: latitude, Longitude: longitude},
  PlaceType: placeType,
  Tooltip: tooltip,
  Type: 'pin',
});

export const sendDragToScroll = () => sendMessage({
  Mode: 'DragToScroll',
  Type: 'pointerDragModeSet',
})

export const sendDragWithinPage = () => sendMessage({
  Mode: 'DragWithinPage',
  Type: 'pointerDragModeSet',
})

export const sendPinEvent = ({
  componentId,
  event,
}) => sendMessage({
  ComponentId: componentId,
  Event: event,
  Type: 'pin',
});

export const sendPinUnmount = (componentId) => sendMessage({
  ComponentId: componentId,
  IsComponentUnmount: true,
  Type: 'pin',
});

export const sendWorldCreate = ({
  isBing = true,
  isGame,
  isPrivate,
  isSolo,
}) => sendMessage({
  IsBing: isBing,
  IsGame: isGame,
  IsPrivate: isPrivate,
  IsSolo: isSolo,
  Type: 'worldCreate',
});

export const sendWorldInvite = () => sendMessage({
  Type: 'worldInvite',
});

export const sendLobbyJoin = () => sendMessage({
  IsLobby: true,
  Type: 'worldJoin',
});

export const sendWorldJoin = ({worldId}) => sendMessage({
  Type: 'worldJoin',
  WorldId: worldId,
});

export const sendBlockedUserIdsRequest = () => sendMessage({
  Type: 'blockedUserIdsRequest',
});

export const sendInWorldUsersListRequest = () => sendMessage({
  Type: 'inWorldUsersListRequest',
});

export const sendWorldsRequest = () => sendMessage({
  Type: 'worlds',
});
